controllers = angular.module("questionnaireApp.controllers", [])

##############################################################################
## formatAuthErrorMsg
##############################################################################
formatAuthErrorMsg = (error) ->
    switch error.code
        when "auth/argument-error" then "Enter a valid e-mail and password."
        when "auth/user-not-found" then "The password is invalid"
        when "auth/wrong-password" then "The password is invalid"
        when "auth/network-request-failed" then error.message
        else error.message


##############################################################################
## QuestionLanguage
##############################################################################
QUESTION_LANGUAGE_ID = '4fe42c11c55d7caf61066a154bbb7906'
QUESTION_LANGUAGE_VALUES = {}

QUESTION_LANGUAGE_VALUES['bf592a0450f32ecb09500797b80e5892'] = {value: 'ar', text: 'Arabic'}
QUESTION_LANGUAGE_VALUES['3676a735f7dba931512b32454075f534'] = {value: 'pt-br', text: 'Brazilian'}
QUESTION_LANGUAGE_VALUES['9511aefa749611e9b5aa784f438a5ad0'] = {value: 'bg', text: 'Bulgarian'}
QUESTION_LANGUAGE_VALUES['5b491bdc139d659515944d6cfc6a59f9'] = {value: 'ca', text: 'Catalan'}
QUESTION_LANGUAGE_VALUES['842a70f73bdfde7c234a25ee1d20b775'] = {value: 'cn', text: 'Chinese'}
QUESTION_LANGUAGE_VALUES['09b77fa6d07c11e88dbb784f438a5ad0'] = {value: 'hr', text: 'Croatian'}
QUESTION_LANGUAGE_VALUES['12362308d07c11e89069784f438a5ad0'] = {value: 'cs', text: 'Czechish'}
QUESTION_LANGUAGE_VALUES['5ae538f4749711e9ba05784f438a5ad0'] = {value: 'da', text: 'Danish'}
QUESTION_LANGUAGE_VALUES['8dd2ce521ecfdc5fc879b9e6d256136c'] = {value: 'nl', text: 'Dutch'}
QUESTION_LANGUAGE_VALUES['837143c9573ed511955d89b303f576e1'] = {value: 'en', text: 'English'}
QUESTION_LANGUAGE_VALUES['7e4948bb124711822a78077dc23dc0b3'] = {value: 'fi', text: 'Finnish'}
QUESTION_LANGUAGE_VALUES['f67b027b965d88b64403a5afe36794c3'] = {value: 'fr', text: 'French'}
QUESTION_LANGUAGE_VALUES['81e7e49ed09711e8b290784f438a5ad0'] = {value: 'fr1',text: 'French1'}
QUESTION_LANGUAGE_VALUES['81ecf28dca3e8d10f1287e0927c6805c'] = {value: 'de', text: 'German'}
QUESTION_LANGUAGE_VALUES['f514bddcd09611e88f03784f438a5ad0'] = {value: 'de1',text: 'German1'}
QUESTION_LANGUAGE_VALUES['fbddcf64d09611e882e2784f438a5ad0'] = {value: 'de2',text: 'German2'}
QUESTION_LANGUAGE_VALUES['c1cb0f34d07b11e89f6a784f438a5ad0'] = {value: 'hu', text: 'Hungarian'}
QUESTION_LANGUAGE_VALUES['0fa22e7f57e95c0d725dae6318460264'] = {value: 'it', text: 'Italian'}
QUESTION_LANGUAGE_VALUES['e4eba66b77fbfd8e287cc8883ee132a8'] = {value: 'jp', text: 'Japanese'}
QUESTION_LANGUAGE_VALUES['07ac3d5d7aca01c6b764af2222837bef'] = {value: 'kr', text: 'Korean'}
QUESTION_LANGUAGE_VALUES['fedae658749611e9a534784f438a5ad0'] = {value: 'lv', text: 'Latvian'}
QUESTION_LANGUAGE_VALUES['d2b67f411d58258c64c636b415034170'] = {value: 'ms', text: 'Malay'}
QUESTION_LANGUAGE_VALUES['da42d9647ca7a9efb39ee77816ac7ef7'] = {value: 'no', text: 'Norwegian'}
QUESTION_LANGUAGE_VALUES['af338066c1be5abc22e3c021d47c1951'] = {value: 'pl', text: 'Polish'}
QUESTION_LANGUAGE_VALUES['2374da1222f0ea4ae9bf176f8813cb6f'] = {value: 'pt', text: 'Portuguese'}
QUESTION_LANGUAGE_VALUES['325abe8452f3f19d4f88a21dd6b0e364'] = {value: 'ro', text: 'Romanian'}
QUESTION_LANGUAGE_VALUES['ddf06c573598c7ae1484e53a027218b4'] = {value: 'ru', text: 'Russian'}
QUESTION_LANGUAGE_VALUES['d0b56ffef312b82f755ca922a2e5f2e1'] = {value: 'sk', text: 'Slovak'}
QUESTION_LANGUAGE_VALUES['8886cf18749511e9bf47784f438a5ad0'] = {value: 'sl', text: 'Slovenian'}
QUESTION_LANGUAGE_VALUES['dc38a18c779ed9390e0f2b7038058d33'] = {value: 'es', text: 'Spanish'}
QUESTION_LANGUAGE_VALUES['363bf143d005ba4fac46aba82c65ca2d'] = {value: 'sv', text: 'Swedish'}
QUESTION_LANGUAGE_VALUES['e103ad15fc341317a37500fe72d97cac'] = {value: 'tw', text: 'Taiwanese'}
QUESTION_LANGUAGE_VALUES['98aa6f879d6d3eb654c0c73137d07212'] = {value: 'th', text: 'Thai'}
QUESTION_LANGUAGE_VALUES['0f3aeb108f67fab4ccb32cb9c90943b9'] = {value: 'tr', text: 'Turkish'}
QUESTION_LANGUAGE_VALUES['1dd77f26ee79dbd2016328d7451bf58b'] = {value: 'uk', text: 'Ukrainian'}

##############################################################################
## b64_decode_autologin
##############################################################################
if not window.btoa?
    base64_encode = window.base64.encode
else
    base64_encode = window.btoa

if not window.atob?
    base64_decode = window.base64.decode
else
    base64_decode = window.atob

b64_decode_autologin = (s) ->

    s = s.replace /-/g, "="
    s = base64_decode(s)
    try
        s = decodeURIComponent(s)
    catch error
        s = "error decoding"

    return s


##############################################################################
## AutologinController
##############################################################################
controllers.controller 'AutologinController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    'Auth'
    ($scope, $location, $routeParams, $cookieStore, Auth) ->
        $scope.firebaseUser        = null
        $scope.user                = {}
        $scope.errorMsg            = null
        $scope.autologin           = b64_decode_autologin($routeParams.autologin)
        $scope.email               = ''
        $scope.password            = ''

        credentials = $scope.autologin.split('##')
        if credentials
            $scope.email    = credentials[0] + '@chbsignin.nl'
            $scope.password = credentials[1]

        Auth.$onAuthStateChanged (firebaseUser) ->
            $scope.firebaseUser = firebaseUser
            return

        try
            Auth.$signInWithEmailAndPassword($scope.email, $scope.password).then(
                (firebaseUser) ->

                    if $location.url().indexOf('question') > -1 and $location.url().indexOf('answer') > -1
                        $cookieStore.put 'question', $routeParams.question
                        $cookieStore.put 'answer',   $routeParams.answer

                    if $location.url().indexOf('q1') > -1 and $location.url().indexOf('a1') > -1
                        $cookieStore.put 'q1', $routeParams.q1
                        $cookieStore.put 'a1', $routeParams.a1

                    if $location.url().indexOf('q2') > -1 and $location.url().indexOf('a2') > -1
                        $cookieStore.put 'q2', $routeParams.q2
                        $cookieStore.put 'a2', $routeParams.a2

                    if $location.url().indexOf('q3') > -1 and $location.url().indexOf('a3') > -1
                        $cookieStore.put 'q3', $routeParams.q3
                        $cookieStore.put 'a3', $routeParams.a3

                    if $location.url().indexOf('unsubscribe') > -1
                        $location.path '/unsubscribe/'
                    else
                        $location.path '/questionnaire/'

                ).catch (error) ->
                    console.log error
                    $location.path '/notauthorized/'
        catch error
            console.error 'Could not start authentication:', error
            $scope.errorMsg = formatAuthErrorMsg error
            return
]


##############################################################################
## AnonymousLoginController
##############################################################################
controllers.controller 'AnonymousLoginController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    'QuestionnaireRef'
    'Auth'
    ($scope, $location, $routeParams, $cookieStore, QuestionnaireRef, Auth) ->
        $scope.errorMsg            = null
        $scope.questionnaire       = $routeParams.questionnaire
        $scope.language            = $routeParams.language
        $scope.surveyLimit         = null


        try

            try
                Auth.$signOut().then(
                    (res) ->
                        $scope.doSignInAnonymously()
                        return
                    ).catch (error) ->
                        $scope.doSignInAnonymously()
                        return
            catch error
                console.log(error)


            $scope.doSignInAnonymously = ->

                $scope.anonymousLimitService = QuestionnaireRef.getQuestionnaireAnonymousLimitService($scope.questionnaire)

                $scope.anonymousLimitService.obj.$bindTo($scope, 'surveyLimit').then ->

                    $scope.limit    = 0
                    $scope.current  = 0

                    if $scope.surveyLimit.hasOwnProperty('limit')
                        $scope.limit = $scope.surveyLimit.limit

                    if $scope.surveyLimit.hasOwnProperty('current')
                        $scope.current = $scope.surveyLimit.current

                    if $location.url().indexOf('question') > -1 and $location.url().indexOf('answer') > -1
                        $cookieStore.put 'question', $routeParams.question
                        $cookieStore.put 'answer',   $routeParams.answer

                    if $location.url().indexOf('q1') > -1 and $location.url().indexOf('a1') > -1
                        $cookieStore.put 'q1', $routeParams.q1
                        $cookieStore.put 'a1', $routeParams.a1

                    if $location.url().indexOf('q2') > -1 and $location.url().indexOf('a2') > -1
                        $cookieStore.put 'q2', $routeParams.q2
                        $cookieStore.put 'a2', $routeParams.a2

                    if $location.url().indexOf('q3') > -1 and $location.url().indexOf('a3') > -1
                        $cookieStore.put 'q3', $routeParams.q3
                        $cookieStore.put 'a3', $routeParams.a3

                    #console.log($scope.current)
                    if $scope.current  < $scope.limit

                            Auth.$signInAnonymously().then(
                                (firebaseUser) ->
                                    $location.path '/questionnaire/' + $scope.questionnaire + '/language/' + $scope.language + '/'
                                    return
                                ).catch (error) ->
                                    console.error 'Anonyouse authentication failed:', error
                                    $scope.errorMsg = formatAuthErrorMsg error
                                    $location.path '/notauthorized/'
                                    return
                    else
                        $location.path '/notauthorized/'


        catch error
            console.error 'Could not start authentication:', error
            $scope.errorMsg = formatAuthErrorMsg error
            return
]



##############################################################################
## LoginController
##############################################################################
controllers.controller 'LoginController', [
    '$scope'
    '$location'
    'FeedbackRef'
    'Auth'
    ($scope, $location, FeedbackRef, Auth) ->
        $scope.user                = {}
        $scope.errorMsg            = null
        $scope.feedbackService     = FeedbackRef.getFeedbackService()
        $scope.feedbackData        = {name:'Your name', email:'Your e-mail address', message:'Message'}
        $scope.feedbackMessageSent = false

        #Auth.$onAuthStateChanged (firebaseUser) ->

        #    if firebaseUser
        #        $location.path '/questionnaire/'
        #    return

        $scope.doLogin = ->
            $scope.errorMsg = null
            try
                $scope.user.email = $scope.user.loginname + '@chbsignin.nl'
                Auth.$signInWithEmailAndPassword($scope.user.email, $scope.user.password).then(
                    (firebaseUser) ->
                        $location.path '/questionnaire/'
                        return
                    ).catch (error) ->
                        console.error 'Authentication failed:', error
                        $scope.errorMsg = formatAuthErrorMsg error
                        return
            catch error
                console.error 'Could not start authentication:', error
                $scope.errorMsg = formatAuthErrorMsg error
                return

        $scope.doSignOut = ->
              Auth.$signOut()

        $scope.sendFeedback = ->
            $scope.feedbackService.obj.$add $scope.feedbackData
            $scope.feedbackData = {name:'Your name', email:'Your e-mail address', message:'Message'}
            $scope.feedbackMessageSent = true

]



##############################################################################
## PreviewController
##############################################################################
controllers.controller 'PreviewController', [
    '$scope'
    '$location'
    '$routeParams'
    '$sce'
    'FeedbackRef'
    'QuestionnairePreviewRef'
    ($scope, $location, $routeParams, $sce, FeedbackRef, QuestionnairePreviewRef) ->

        $scope.settings =
            questionnaireId:            $routeParams.questionnaire
            questionnaireScale:         6
            questionnaireSubindicators: []
            respondentLanguage:         $routeParams.language
            browsingState:              'STATE_BROWSE'

        $scope.feedbackService              = FeedbackRef.getFeedbackService()
        $scope.questionnaireInfoService     = QuestionnairePreviewRef.getQuestionnaireInfoService($scope.settings.questionnaireId)
        $scope.questionnaireItemsService    = QuestionnairePreviewRef.getQuestionnaireItemsService($scope.settings.questionnaireId)
        $scope.questionnaireLabelsService   = QuestionnairePreviewRef.getQuestionnaireLabelsService($scope.settings.questionnaireId)
        $scope.questionnaireSegmentsService = QuestionnairePreviewRef.getQuestionnaireSegmentsService($scope.settings.questionnaireId)
        $scope.questionnaireSheets          = [[]]
        $scope.questionnaireLabels          = null
        $scope.questionnaireTriggers        = null
        $scope.respondentResponses          = {metadata:{'browsingHistory':[]}}
        $scope.currentSheetPosition         = 0
        $scope.requiredItemsIds             = []
        $scope.progressBarRatio             = 0
        $scope.initializations              = {questionnaireInfo:false, questionnaireItems:false, questionnaireSegments:false, questionnaireLabels:false}
        $scope.questionnaireCoreItemsLabels = {header : '', footer: '', forward : '', backward : '', end:'', thankyou:'' }
        $scope.contentLoaded                = false
        $scope.isQuestionnaireCompleted     = false
        $scope.feedbackData                 = {name:'Your name', email:'Your e-mail address', message:'Message', survey:$scope.settings.questionnaireId, respondent:'preview'}
        $scope.feedbackMessageSent          = false
        $scope.dialogCommentActions         = [{ text: 'Ok', primary: true }]
        $scope.dialogCommentVisible         = false
        $scope.dialogCommentValue           = ''

        $scope.questionnaireInfoService.obj.$loaded().then ->

            if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('experience')
                if $scope.questionnaireInfoService.obj.info.subindicators.experience
                    $scope.settings.questionnaireSubindicators.push {id: 'subindicator_experience', prefix:'E', scale:Number($scope.questionnaireInfoService.obj.info.scale) + 1}

            if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('importance')
                if $scope.questionnaireInfoService.obj.info.subindicators.importance
                    $scope.settings.questionnaireSubindicators.push {id: 'subindicator_importance', prefix:'I', scale:5}

            if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('performance')
                if $scope.questionnaireInfoService.obj.info.subindicators.performance
                    $scope.settings.questionnaireSubindicators.push {id: 'subindicator_performance', prefix:'P', scale:4}

            if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('comment')
                if $scope.questionnaireInfoService.obj.info.subindicators.comment
                    $scope.settings.questionnaireSubindicators.push {id: 'subindicator_comment', prefix:'C',scale:1}

            $scope.settings.questionnaireScale = Number($scope.questionnaireInfoService.obj.info.scale)

            $scope.initializations.questionnaireInfo = true


        $scope.questionnaireLabelsService.obj.$loaded().then ->
            $scope.initializations.questionnaireLabels = true


        $scope.questionnaireItemsService.obj.$loaded().then ->

            for questionnaireItem in $scope.questionnaireItemsService.obj
                if questionnaireItem.type == 'PAGEBREAK'
                    $scope.questionnaireSheets.push []
                else
                    $scope.questionnaireSheets[$scope.questionnaireSheets.length - 1].push questionnaireItem

            $scope.initializations.questionnaireItems = true

        $scope.questionnaireSegmentsService.obj.$loaded().then ->
            $scope.initializations.questionnaireSegments = true

        $scope.AddCurrentSheetToBrowsingHistory = ->
            $scope.respondentResponses.metadata.browsingHistory.push $scope.currentSheetPosition


        $scope.RemoveSheetFromBrowsingHistory = ->
            if $scope.respondentResponses.metadata.browsingHistory
                return $scope.respondentResponses.metadata.browsingHistory.pop()
            else
                return 0


        $scope.calculateProgressBarRatio = ->
            $scope.progressBarRatio     = parseInt(100 * ($scope.currentSheetPosition + 1) / $scope.questionnaireSheets.length)


        $scope.navigateToPreviousSheet = ->
            if $scope.currentSheetPosition > 0
                $scope.currentSheetPosition = $scope.RemoveSheetFromBrowsingHistory()
                $scope.calculateProgressBarRatio()
                $scope.moveToAnchorTop()


        $scope.navigateToNextSheet = ->
            if $scope.currentSheetPosition < $scope.questionnaireSheets.length - 1
                if $scope.validateRequiredQuestionnaireItems()
                    $scope.settings.browsingState = 'STATE_BROWSE'
                    $scope.AddCurrentSheetToBrowsingHistory()
                    $scope.currentSheetPosition  = $scope.calculateNextSheetPosition()
                    $scope.calculateProgressBarRatio()
                    $scope.moveToAnchorTop()
                else
                    $scope.settings.browsingState = 'STATE_CHECK_REQUIRED'


        $scope.navigateToThankYouMessage = ->
            $scope.isQuestionnaireCompleted = true


        $scope.validateRequiredQuestionnaireItems = ->
            $scope.requiredItemsIds         = []
            requiredQuestionnaireItemsValid = true
            for questionnaireItem in $scope.questionnaireSheets[$scope.currentSheetPosition]
                if $scope.questionnaireTriggers.isQuestionnaireItemVisible(questionnaireItem, $scope.respondentResponses)
                    if questionnaireItem.type == 'SUCCESSFACTOR'
                        questionnaireItemResponseValidator = new SuccessfactorItemResponseValidator(questionnaireItem, $scope.questionnaireTriggers, $scope.respondentResponses, $scope.requiredItemsIds)
                    if questionnaireItem.type == 'MATRIX'
                        questionnaireItemResponseValidator = new MatrixItemResponseValidator(questionnaireItem, $scope.questionnaireTriggers, $scope.respondentResponses, $scope.requiredItemsIds)
                    if questionnaireItem.type == 'QUESTION'
                        questionnaireItemResponseValidator = new QuestionItemResponseValidator(questionnaireItem, $scope.questionnaireTriggers, $scope.respondentResponses, $scope.requiredItemsIds)
                    if questionnaireItemResponseValidator
                        responseValid = questionnaireItemResponseValidator.isQuestionnaireItemResponseValid()
                        if not responseValid
                            requiredQuestionnaireItemsValid = false
            return requiredQuestionnaireItemsValid

        $scope.moveToAnchorTop = ->
            window.scrollTo(0, 0)

        $scope.getSheetNumberForQuestionnaireItem = (questionnaireItemId) ->
            for sheetPosition in [0...$scope.questionnaireSheets.length] by 1
                for questionnaireSheetItem in $scope.questionnaireSheets[sheetPosition]
                    if questionnaireSheetItem.id == questionnaireItemId
                        return sheetPosition
            return -1


        $scope.calculateNextSheetPosition = ->
            skipToQuestionnaireItemIds = []
            for questionnaireItem in $scope.questionnaireSheets[$scope.currentSheetPosition]
                if questionnaireItem.type == 'SKIP'
                    if $scope.questionnaireTriggers.isQuestionnaireItemTriggerConditionMet(questionnaireItem, $scope.respondentResponses)
                        skipToQuestionnaireItemIds.push $scope.getSheetNumberForQuestionnaireItem(questionnaireItem.target)
            if skipToQuestionnaireItemIds.length > 0
                return skipToQuestionnaireItemIds[0]
            else
                for nextPosition in [$scope.currentSheetPosition + 1...$scope.questionnaireSheets.length] by 1
                    for questionnaireItem in $scope.questionnaireSheets[nextPosition]
                        if $scope.questionnaireTriggers.isQuestionnaireItemVisible(questionnaireItem, $scope.respondentResponses)
                            return nextPosition
                return $scope.currentSheetPosition


        $scope.sendFeedback = ->
            $scope.feedbackService.obj.$add $scope.feedbackData
            $scope.feedbackData = {name:'Your name', email:'Your e-mail address', message:'Message', survey:$scope.settings.questionnaireId, respondent:'preview'}
            $scope.feedbackMessageSent = true


        $scope.$watch "respondentResponses", ((newVal, oldVal) ->

            if newVal.hasOwnProperty(QUESTION_LANGUAGE_ID)
                if newVal[QUESTION_LANGUAGE_ID]
                    lang = QUESTION_LANGUAGE_VALUES[newVal[QUESTION_LANGUAGE_ID]]['value']
                    if lang != $scope.settings.respondentLanguage
                        $scope.settings.respondentLanguage = lang
                        $scope.questionnaireCoreItemsLabels.header   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('HEADER', $scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.footer   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('FOOTER', $scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.forward  =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_FORWARD', $scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.backward =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_BACKWARD',$scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.end      =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_END',$scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.thankyou =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('THANKYOU',$scope.settings.respondentLanguage))
            ), true



        $scope.$watch "initializations", ((newVal, oldVal) ->

              if newVal
                  if  newVal.questionnaireInfo && newVal.questionnaireLabels
                      if not $scope.questionnaireLabels
                          $scope.questionnaireLabels = new QuestionnaireLabelsObject($scope.settings.questionnaireScale, $scope.questionnaireLabelsService.obj, null, $scope.questionnaireInfoService.obj.info.organizationname)
                          $scope.questionnaireCoreItemsLabels.header   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('HEADER', $scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.footer   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('FOOTER', $scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.forward  =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_FORWARD', $scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.backward =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_BACKWARD',$scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.end      =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_END',$scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.thankyou =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('THANKYOU',$scope.settings.respondentLanguage))


                  if  newVal.questionnaireItems && newVal.questionnaireSegments
                      if not $scope.questionnaireTriggers
                          $scope.questionnaireTriggers = new QuestionnaireTriggersObject $scope.questionnaireItemsService.obj, $scope.questionnaireSegmentsService.obj, {}
                          $scope.questionnaireTriggers.disableRespondentChecking()

                  if not $scope.contentLoaded
                      $scope.calculateProgressBarRatio()
                      pageInitialized = true
                      for key,value of newVal
                          if not value
                              pageInitialized=false
                      $scope.contentLoaded = pageInitialized
                      $('.card').fadeIn(900)
            ), true


        $scope.$on "onSelectComment", (e, arg) ->
            $scope.dialogCommentVisible = true
            $scope.selectedItem         = arg['item']
            $scope.selectedItemLabel    = $sce.trustAsHtml(arg['label'])
            $scope.dialogCommentTitle   = $scope.questionnaireLabels.getQuestionnaireItemLabelByName('C_1', $scope.settings.respondentLanguage)

            dialog = $('#dialog').data("kendoDialog")
            if dialog
                dialog.open()
                $('#dialogComment').focus();
]



##############################################################################
## QuestionnaireController
##############################################################################
controllers.controller 'QuestionnaireController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    '$sce'
    'QuestionnaireRef'
    'RespondentRef'
    'LoginRef'
    'FeedbackRef'
    'ConnectionRef'
    'Auth'
    'currentAuth'
    ($scope, $location, $routeParams, $cookieStore, $sce, QuestionnaireRef, RespondentRef, LoginRef, FeedbackRef, ConnectionRef, Auth, currentAuth) ->

        $scope.settings =
            questionnaireId:            null
            questionnaireScale:         6
            questionnaireSubindicators: []
            respondentLanguage:         'nl'
            browsingState:              'STATE_BROWSE'


        $scope.feedbackService              = FeedbackRef.getFeedbackService()
        $scope.connectionService            = ConnectionRef.getConnectionService()
        $scope.questionnaireInfoService     = null
        $scope.questionnaireItemsService    = null
        $scope.questionnaireLabelsService   = null
        $scope.questionnaireSegmentsService = null
        $scope.respondentResponsesService   = null
        $scope.respondentInfoService        = null
        $scope.questionnaireSheets          = [[]]
        $scope.questionnaireLabels          = null
        $scope.questionnaireTriggers        = null
        $scope.respondentResponses          = {}
        $scope.currentSheetPosition         = 0
        $scope.requiredItemsIds             = []
        $scope.progressBarRatio             = 0
        $scope.initializations              = {questionnaireInfo:false, questionnaireItems:false, questionnaireSegments:false, respondentResponses:false, respondentInfo:false, questionnaireLabels:false, entryInfo : false}
        $scope.checkRespondentResponses     = false
        $scope.settingsInitialized          = false
        $scope.questionnaireCoreItemsLabels = {header : '', footer: '', forward : '', backward : '', end: '', thankyou:'' }
        $scope.contentLoaded                = false
        $scope.isQuestionnaireCompleted     = false
        $scope.feedbackData                 = {name:'Your name', email:'Your e-mail address', message:'Message', survey:$scope.settings.questionnaireId, respondent:currentAuth.uid}
        $scope.feedbackMessageSent          = false
        $scope.dialogCommentActions         = [{ text: 'Ok', primary: true }]
        $scope.dialogCommentVisible         = false
        $scope.dialogCommentValue           = ''
        $scope.surveyLimit                  = null
        $scope.markAnonymousAsRespondent    = false
        $scope.clientConnected              = false
        $scope.entryQuestion                = null
        $scope.entryAnswer                  = null
        $scope.entryList                    = []

        if $cookieStore.get('question') and $cookieStore.get('answer')
                $scope.entryList.push {question : $cookieStore.get('question'), answer : $cookieStore.get('answer')}
                $cookieStore.remove("question")
                $cookieStore.remove("answer")

        if $cookieStore.get('q1') and $cookieStore.get('a1')
                $scope.entryList.push {question : $cookieStore.get('q1'), answer : $cookieStore.get('a1')}
                $cookieStore.remove("q1")
                $cookieStore.remove("a1")

        if $cookieStore.get('q2') and $cookieStore.get('a2')
                $scope.entryList.push {question : $cookieStore.get('q2'), answer : $cookieStore.get('a2')}
                $cookieStore.remove("q2")
                $cookieStore.remove("a2")

        if $cookieStore.get('q3') and $cookieStore.get('a3')
                $scope.entryList.push {question : $cookieStore.get('q3'), answer : $cookieStore.get('a3')}
                $cookieStore.remove("q3")
                $cookieStore.remove("a3")

        if currentAuth.isAnonymous
            $scope.settings.questionnaireId    =  $routeParams.questionnaire
            $scope.settings.respondentLanguage =  $routeParams.language

            $scope.anonymousLimitService       = QuestionnaireRef.getQuestionnaireAnonymousLimitService($scope.settings.questionnaireId)

            $scope.anonymousLimitService.obj.$bindTo($scope, 'surveyLimit').then ->
                $scope.settingsInitialized  =  true
        else
            $scope.loginService                 = LoginRef.getLoginService(currentAuth.uid)

            $scope.loginService.obj.$loaded().then ->
                if $scope.loginService.obj.$value
                    $scope.settings.questionnaireId   =  $scope.loginService.obj.$value
                    $scope.settingsInitialized        =  true
                else
                    $location.path '/notauthorized/'


        $scope.connectionService.ref.on 'value', (snap) ->
            $scope.prevclientConnected = $scope.clientConnected
            $scope.clientConnected = snap.val()
            if $scope.prevclientConnected == true and snap.val() == false
                if confirm("The connection is lost. Please click 'Ok' to refresh the page of try again later! ") == true
                    window.location.reload()


        $scope.initialize = ->
            $scope.questionnaireInfoService     = QuestionnaireRef.getQuestionnaireInfoService($scope.settings.questionnaireId)
            $scope.questionnaireItemsService    = QuestionnaireRef.getQuestionnaireItemsService($scope.settings.questionnaireId)
            $scope.questionnaireLabelsService   = QuestionnaireRef.getQuestionnaireLabelsService($scope.settings.questionnaireId)
            $scope.questionnaireSegmentsService = QuestionnaireRef.getQuestionnaireSegmentsService($scope.settings.questionnaireId)
            $scope.respondentResponsesService   = RespondentRef.getRespondentResponsesService($scope.settings.questionnaireId,currentAuth.uid)
            $scope.respondentInfoService        = RespondentRef.getRespondentService($scope.settings.questionnaireId,currentAuth.uid)


            $scope.questionnaireInfoService.obj.$loaded().then ->

                if currentAuth.isAnonymous and $scope.questionnaireInfoService.obj.info.type == 'LOGIN'
                    $location.path '/notauthorized/'

                if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('experience')
                    if $scope.questionnaireInfoService.obj.info.subindicators.experience
                        $scope.settings.questionnaireSubindicators.push {id: 'subindicator_experience', prefix:'E', scale:Number($scope.questionnaireInfoService.obj.info.scale) + 1}

                if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('importance')
                    if $scope.questionnaireInfoService.obj.info.subindicators.importance
                        $scope.settings.questionnaireSubindicators.push {id: 'subindicator_importance', prefix:'I', scale:5}

                if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('performance')
                    if $scope.questionnaireInfoService.obj.info.subindicators.performance
                        $scope.settings.questionnaireSubindicators.push {id: 'subindicator_performance', prefix:'P', scale:4}

                if $scope.questionnaireInfoService.obj.info.subindicators.hasOwnProperty('comment')
                    if $scope.questionnaireInfoService.obj.info.subindicators.comment
                        $scope.settings.questionnaireSubindicators.push {id: 'subindicator_comment', prefix:'C',scale:1}

                $scope.settings.questionnaireScale = Number($scope.questionnaireInfoService.obj.info.scale)

                $scope.initializations.questionnaireInfo = true


            $scope.questionnaireLabelsService.obj.$loaded().then ->
                $scope.initializations.questionnaireLabels = true


            $scope.respondentInfoService.obj.$loaded().then ->
                $scope.initializations.respondentInfo = true
                if not currentAuth.isAnonymous
                    $scope.settings.respondentLanguage = $scope.respondentInfoService.obj.language


            $scope.questionnaireItemsService.obj.$loaded().then ->

                for questionnaireItem in $scope.questionnaireItemsService.obj
                    if questionnaireItem.type == 'PAGEBREAK'
                        $scope.questionnaireSheets.push []
                    else
                        $scope.questionnaireSheets[$scope.questionnaireSheets.length - 1].push angular.copy(questionnaireItem)

                #console.log $scope.questionnaireSheets

                $scope.initializations.questionnaireItems = true


            $scope.questionnaireSegmentsService.obj.$loaded().then ->
                $scope.initializations.questionnaireSegments = true


            $scope.respondentResponsesService.obj.$bindTo($scope, 'respondentResponses').then ->
                if not $scope.respondentResponses.hasOwnProperty('metadata')
                    $scope.respondentResponses.metadata = {}
                if not $scope.respondentResponses.metadata.hasOwnProperty('firstlogintime')
                    $scope.respondentResponses.metadata.firstlogintime = Date.now()
                if not $scope.respondentResponses.metadata.hasOwnProperty('lastsheetviewed')
                    $scope.respondentResponses.metadata.lastsheetviewed = $scope.currentSheetPosition
                else
                    $scope.currentSheetPosition = Number($scope.respondentResponses.metadata.lastsheetviewed)

                $scope.initializations.respondentResponses = true
                return

        $scope.AddCurrentSheetToBrowsingHistory = ->
            if not $scope.respondentResponses.metadata.hasOwnProperty('browsingHistory')
                $scope.respondentResponses.metadata.browsingHistory = []

            $scope.respondentResponses.metadata.browsingHistory.push $scope.currentSheetPosition


        $scope.RemoveSheetFromBrowsingHistory = ->
            if $scope.respondentResponses.metadata.browsingHistory
                return $scope.respondentResponses.metadata.browsingHistory.pop()
            else
                return 0


        $scope.calculateProgressBarRatio = ->
            $scope.progressBarRatio     = parseInt(100 * ($scope.currentSheetPosition + 1) / $scope.questionnaireSheets.length)


        $scope.navigateToPreviousSheet = ->
            if $scope.currentSheetPosition > 0
                $scope.currentSheetPosition = $scope.RemoveSheetFromBrowsingHistory()
                $scope.calculateProgressBarRatio()
                $scope.moveToAnchorTop()


        $scope.navigateToNextSheet = ->
            if $scope.currentSheetPosition < $scope.questionnaireSheets.length - 1
                if $scope.validateRequiredQuestionnaireItems()
                    $scope.settings.browsingState = 'STATE_BROWSE'
                    $scope.AddCurrentSheetToBrowsingHistory()
                    $scope.currentSheetPosition  = $scope.calculateNextSheetPosition()
                    $scope.respondentResponses.metadata.lastsheetviewed = $scope.currentSheetPosition
                    $scope.calculateProgressBarRatio()
                    $scope.moveToAnchorTop()
                else
                    $scope.settings.browsingState = 'STATE_CHECK_REQUIRED'


        $scope.navigateToThankYouMessage = ->
            if $scope.validateRequiredQuestionnaireItems()
                $scope.settings.browsingState   = 'STATE_BROWSE'
                $scope.isQuestionnaireCompleted = true
                REDIRECT     = '/login/'
                looping      = false

                if $scope.questionnaireInfoService.obj.info.hasOwnProperty('looping')
                    looping = $scope.questionnaireInfoService.obj.info['looping']

                if currentAuth.isAnonymous and looping
                    REDIRECT = '/anonymouslogin/questionnaire/' +  $scope.settings.questionnaireId + '/language/' + $scope.settings.respondentLanguage
                else
                    REDIRECT = '/thankyou/' + $scope.settings.respondentLanguage + '/'


                Auth.$signOut().then(
                    (res) ->
                        $location.path REDIRECT
                        return
                    ).catch (error) ->
                        $location.path REDIRECT
                        return

                #window.setTimeout (->
                #    Auth.$signOut()
                #    $location.path REDIRECT
                #    return
                #), 2000

            else
                $scope.settings.browsingState = 'STATE_CHECK_REQUIRED'


        $scope.validateRequiredQuestionnaireItems = ->
            $scope.requiredItemsIds         = []
            requiredQuestionnaireItemsValid = true
            for questionnaireItem in $scope.questionnaireSheets[$scope.currentSheetPosition]
                if $scope.questionnaireTriggers.isQuestionnaireItemVisible(questionnaireItem, $scope.respondentResponses)
                    if questionnaireItem.type == 'SUCCESSFACTOR'
                        questionnaireItemResponseValidator = new SuccessfactorItemResponseValidator(questionnaireItem, $scope.questionnaireTriggers, $scope.respondentResponses, $scope.requiredItemsIds)
                    if questionnaireItem.type == 'MATRIX'
                        questionnaireItemResponseValidator = new MatrixItemResponseValidator(questionnaireItem, $scope.questionnaireTriggers, $scope.respondentResponses, $scope.requiredItemsIds)
                    if questionnaireItem.type == 'QUESTION'
                        questionnaireItemResponseValidator = new QuestionItemResponseValidator(questionnaireItem, $scope.questionnaireTriggers, $scope.respondentResponses, $scope.requiredItemsIds)
                    if questionnaireItemResponseValidator
                        responseValid = questionnaireItemResponseValidator.isQuestionnaireItemResponseValid()
                        if not responseValid
                            requiredQuestionnaireItemsValid = false
            return requiredQuestionnaireItemsValid

        $scope.moveToAnchorTop = ->
            window.scrollTo(0, 0)


        $scope.getSheetNumberForQuestionnaireItem = (questionnaireItemId) ->
            for sheetPosition in [0...$scope.questionnaireSheets.length] by 1
                for questionnaireSheetItem in $scope.questionnaireSheets[sheetPosition]
                    if questionnaireSheetItem.id == questionnaireItemId
                        return sheetPosition
            return -1


        $scope.calculateNextSheetPosition = ->
            skipToQuestionnaireItemIds = []
            for questionnaireItem in $scope.questionnaireSheets[$scope.currentSheetPosition]
                if questionnaireItem.type == 'SKIP'
                    if $scope.questionnaireTriggers.isQuestionnaireItemTriggerConditionMet(questionnaireItem, $scope.respondentResponses)
                        skipToQuestionnaireItemIds.push $scope.getSheetNumberForQuestionnaireItem(questionnaireItem.target)
            if skipToQuestionnaireItemIds.length > 0
                return skipToQuestionnaireItemIds[0]
            else
                for nextPosition in [$scope.currentSheetPosition + 1...$scope.questionnaireSheets.length] by 1
                    for questionnaireItem in $scope.questionnaireSheets[nextPosition]
                        if $scope.questionnaireTriggers.isQuestionnaireItemVisible(questionnaireItem, $scope.respondentResponses)
                            return nextPosition
                return $scope.currentSheetPosition



        $scope.InitializeEntryInfo = ->

            for entry in $scope.entryList
                $scope.respondentResponses[entry.question] = entry.answer
                questionSheetPosition = $scope.getSheetNumberForQuestionnaireItem(entry.question)
                if questionSheetPosition > -1
                    $scope.currentSheetPosition = questionSheetPosition
            $scope.initializations.entryInfo = true


        $scope.sendFeedback = ->
            $scope.feedbackService.obj.$add $scope.feedbackData
            $scope.feedbackData = {name:'Your name', email:'Your e-mail address', message:'Message', survey:$scope.settings.questionnaireId, respondent:currentAuth.uid}
            $scope.feedbackMessageSent = true


        $scope.$watch "respondentResponses", ((newVal, oldVal) ->

            if newVal.hasOwnProperty(QUESTION_LANGUAGE_ID)
                if newVal[QUESTION_LANGUAGE_ID]
                    lang = QUESTION_LANGUAGE_VALUES[newVal[QUESTION_LANGUAGE_ID]]['value']
                    if lang != $scope.settings.respondentLanguage
                        $scope.settings.respondentLanguage = lang
                        $scope.questionnaireCoreItemsLabels.header   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('HEADER', $scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.footer   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('FOOTER', $scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.forward  =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_FORWARD', $scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.backward =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_BACKWARD',$scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.end      =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_END',$scope.settings.respondentLanguage))
                        $scope.questionnaireCoreItemsLabels.thankyou =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('THANKYOU',$scope.settings.respondentLanguage))


            if currentAuth.isAnonymous and $scope.markAnonymousAsRespondent == false
                $scope.markAnonymousAsRespondent = true
                if $scope.surveyLimit.hasOwnProperty('current')
                    $scope.surveyLimit.current = $scope.surveyLimit.current + 1
                else
                    $scope.surveyLimit.current = 1

            ), true



        $scope.$watch "initializations", ((newVal, oldVal) ->

              if newVal
                  if  newVal.questionnaireInfo && newVal.respondentResponses && newVal.respondentInfo && newVal.questionnaireLabels
                      if not $scope.questionnaireLabels
                          $scope.questionnaireLabels = new QuestionnaireLabelsObject($scope.settings.questionnaireScale, $scope.questionnaireLabelsService.obj, $scope.respondentInfoService.obj)
                          $scope.questionnaireCoreItemsLabels.header   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('HEADER', $scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.footer   =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('FOOTER', $scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.forward  =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_FORWARD', $scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.backward =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_BACKWARD',$scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.end      =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('BUTTON_END',$scope.settings.respondentLanguage))
                          $scope.questionnaireCoreItemsLabels.thankyou =  $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('THANKYOU',$scope.settings.respondentLanguage))

                      if not newVal.entryInfo
                        $scope.InitializeEntryInfo()


                  if  newVal.questionnaireItems && newVal.questionnaireSegments && newVal.respondentInfo && newVal.respondentResponses
                      if not $scope.questionnaireTriggers
                          $scope.questionnaireTriggers = new QuestionnaireTriggersObject $scope.questionnaireItemsService.obj, $scope.questionnaireSegmentsService.obj, $scope.respondentInfoService.obj


                  if  newVal.respondentInfo && newVal.respondentResponses
                      if not $scope.checkRespondentResponses
                          if $scope.respondentInfoService.obj.type == 'TEST'
                              keysToDelete                = []
                              $scope.currentSheetPosition = 0
                              for key,value of $scope.respondentResponses
                                  if key not in ['$id','$priority','metadata']
                                    keysToDelete.push key
                              for key in keysToDelete
                                  delete $scope.respondentResponses[key]
                          $scope.checkRespondentResponses = true

                  if not $scope.contentLoaded
                      $scope.calculateProgressBarRatio()
                      pageInitialized = true
                      for key,value of newVal
                          if not value
                              pageInitialized=false
                      $scope.contentLoaded = pageInitialized
                      $('.card').fadeIn(900)
            ), true



        $scope.$watch "settingsInitialized", ((newVal, oldVal) ->

              if newVal
                  $scope.initialize()

            ), true


        $scope.$on "onSelectComment", (e, arg) ->
            $scope.dialogCommentVisible = true
            $scope.selectedItem       = arg['item']
            $scope.selectedItemLabel  = $sce.trustAsHtml(arg['label'])
            $scope.dialogCommentTitle = $scope.questionnaireLabels.getQuestionnaireItemLabelByName('C_1', $scope.settings.respondentLanguage)

            dialog = $('#dialog').data("kendoDialog")
            if dialog
                dialog.open()
                $('#dialogComment').focus();
]



##############################################################################
## UnsubscribeController
##############################################################################
controllers.controller 'UnsubscribeController', [
    '$scope'
    'BlacklistRef'
    'RespondentRef'
    'LoginRef'
    'currentAuth'
    '$sce'
    ($scope, BlacklistRef, RespondentRef, LoginRef, currentAuth, $sce) ->
        $scope.emails   = {}
        $scope.settings =
            questionnaireId:            null
            respondentLanguage:         'nl'
        $scope.selected         = {email:'', reason:'', other:''}
        $scope.feedbackMessage  = ''
        $scope.labels           = {}

        $scope.REASON_QUESTION = {
                'id'    : 'UNSUBSCRIBE_REASON_QUESTION'
                'items' : [
                            'UNSUBSCRIBE_REASON_NO_TIME',
                            'UNSUBSCRIBE_REASON_NO_INTEREST',
                            'UNSUBSCRIBE_REASON_SATISFIED',
                            'UNSUBSCRIBE_REASON_NOT_SATISFIED',
                            'UNSUBSCRIBE_REASON_NO_EXPERIENCE',
                            'UNSUBSCRIBE_REASON_NO_CONTACT',
                            'UNSUBSCRIBE_REASON_OTHER_NAMELY',
                            'UNSUBSCRIBE_REASON_KEEP_PRIVATE'
                        ]
        }

        $scope.questionnaireLabels = new QuestionnaireLabelsObject(6, {}, null, null)

        if currentAuth.isAnonymous
            $scope.settings.questionnaireId    =  $routeParams.questionnaire
            $scope.settings.respondentLanguage =  $routeParams.language

            if $scope.settings.respondentLanguage != 'nl'
                $scope.settings.respondentLanguage = 'en'
        else
            $scope.loginService                 = LoginRef.getLoginService(currentAuth.uid)

            $scope.loginService.obj.$loaded().then ->
                if $scope.loginService.obj.$value
                    $scope.settings.questionnaireId   = $scope.loginService.obj.$value
                    $scope.respondentInfoService      = RespondentRef.getRespondentService($scope.settings.questionnaireId,currentAuth.uid)

                    $scope.respondentInfoService.obj.$loaded().then ->

                        $scope.settings.respondentLanguage = $scope.respondentInfoService.obj.language

                        if $scope.settings.respondentLanguage != 'nl'
                            $scope.settings.respondentLanguage = 'en'

                        $scope.labels['UNSUBSCRIBE_TITLE']           = $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('UNSUBSCRIBE_TITLE', $scope.settings.respondentLanguage))
                        $scope.labels['UNSUBSCRIBE_REASON_QUESTION'] = $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('UNSUBSCRIBE_REASON_QUESTION', $scope.settings.respondentLanguage))
                        $scope.labels['UNSUBSCRIBE_BUTTON']          = $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('UNSUBSCRIBE_BUTTON', $scope.settings.respondentLanguage))
                        $scope.labels['UNSUBSCRIBE_FEEDBACK']        = $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName('UNSUBSCRIBE_FEEDBACK', $scope.settings.respondentLanguage))
                        for item in $scope.REASON_QUESTION['items']
                            $scope.labels[item] = $sce.trustAsHtml($scope.questionnaireLabels.getQuestionnaireItemLabelByName(item, $scope.settings.respondentLanguage))

        $scope.addRespondentToBlacklist = ->
            $scope.blacklistService    = BlacklistRef.getBlacklistServiceForRespondent($scope.settings.questionnaireId, currentAuth.uid)

            $scope.blacklistService.ref.set {'reason' : $scope.selected.reason, 'other': $scope.selected.other}
            $scope.feedbackMessage = $scope.labels['UNSUBSCRIBE_FEEDBACK']
            $scope.selected        = {email:'', reason:'', other:''}

]



##############################################################################
## NotAuthorizedController
##############################################################################
controllers.controller 'NotAuthorizedController', [
    '$scope'
    ($scope) ->
        return
]


##############################################################################
## ThankYouController
##############################################################################
controllers.controller 'ThankYouController', [
    '$scope'
    '$routeParams'
    ($scope, $routeParams) ->
        $scope.language    =  $routeParams.language
        return
]



##############################################################################
## UpgradeController
##############################################################################
controllers.controller 'UpgradeController', [
    '$scope'
    ($scope) ->
        return
]
